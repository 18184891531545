@tailwind base;
@tailwind components;
@tailwind utilities;

/* === Глобальные переменные === */
:root {
  --color-bg: #f9f9f9;
  --color-text: #374151;
  --color-text-secondary: #6b7280;
  --color-primary: #d1d5db;
  --color-primary-light: #ffd699;
  --color-primary-dark: #cc7a00;
  --color-secondary: #000000;
  --color-secondary-light: #99ddff;
  --color-secondary-dark: #006bb3;
  --color-border: #e5e7eb;
  --color-muted: #d1d5db;
  --color-bg-transparent: rgba(255, 255, 255, 0.7);
  /* Переменные для логотипа */
  --color-logo-symbol: #000000;
  --color-logo-k: #000000;/* Золотой акцент */
  --color-logo-amoza: #000000; /* Бирюзовый */
  --color-logo-kz: #000000;
  --color-logo-shadow: rgba(0, 0, 0, 0.3);
  --color-active-underline: #000000;
}

.dark {
  --color-bg: #1a1a1a;
  --color-text: #e0e0e0;
  --color-text-secondary: #a1a1a1;
  --color-primary: #4b5563;
  --color-primary-light: #ffe680;
  --color-primary-dark: #cc9900;
  --color-secondary: #FFFFFF;
  --color-secondary-light: #66ccff;
  --color-secondary-dark: #0066cc;
  --color-border: #333333;
  --color-muted: #4b5563;
  /* Переменные для логотипа */
  --color-logo-symbol: #ffffff;
  --color-logo-k: #ffffff;
  --color-logo-amoza: #ffffff; /* Или темный бирюзовый */
  --color-logo-kz: #ffffff;
  --color-logo-shadow: rgba(255, 255, 255, 0.3);
  --color-active-underline: #ffffff;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: theme('colors.background.DEFAULT');
  color: theme('colors.text.DEFAULT');
  transition: background-color 0.3s ease, color 0.3s ease;
  @apply font-sans tracking-wider;
}

/* Контейнеры */
.container {
  @apply max-w-screen-lg mx-auto px-3 md:px-8;
}

/* Кнопки */
.button {
  @apply px-4 py-2 font-bold rounded-lg transition duration-300 ease-in-out;
}

/* Основная кнопка (Primary) */
.button-primary {
  @apply bg-green-500 shadow-lg text-primary;
}

.button-primary:hover {
  @apply bg-green-600 scale-105;
}

.button-primary:active {
  @apply transform scale-95 shadow-md;
}

/* Второстепенная кнопка (Secondary) */
.button-secondary {
  @apply border-2 border-secondary text-secondary dark:border-secondary-light;
  color: var(--color-text);
}

.button-secondary:hover {
  @apply bg-secondary text-white dark:bg-secondary-dark;
}

.button-secondary:active {
  @apply transform scale-95 shadow-md;
}


/* Карточки */
.card {
  @apply flex flex-col items-center p-2 md:p-3 lg:p-4 rounded-lg shadow-lg border border-gray-300 dark:border-gray-700;
  @apply bg-transparent dark:text-white;
  @apply hover:scale-105 transition-transform duration-300 ease-in-out;
}

/* Карточка с акцентом */
.card-primary {
  @apply border-primary bg-primary-light text-primary-dark dark:bg-primary dark:text-white dark:border-primary-light;
}

/* Анимации при наведении */
.card:hover {
  @apply shadow-xl;
}

.card-primary:hover {
  @apply bg-primary text-white dark:bg-primary-light dark:text-primary-dark;
}

/* === Модальное окно === */
.modal-overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50;
}

.modal-content {
  @apply container bg-white dark:bg-gray-800 mx-auto p-4 rounded-lg shadow-lg relative max-w-sm w-full;
}

.modal-header {
  @apply text-xl font-bold text-primary dark:text-primary-light mb-4;
}

.modal-body {
  @apply text-base text-text-secondary leading-relaxed mb-6;
}

/* === Градиенты === */
.gradient-primary {
  background: linear-gradient(90deg, theme('colors.primary.light'), theme('colors.secondary.light'));
}

/* === Разделители === */
.divider {
  @apply border-t border-border opacity-70;
}

/* Изображения */
img {
  max-width: 100%;
  height: auto;
  display: block;
  loading: lazy;
}

/* Стили для логотипа */
.logo-symbol {
  color: var(--color-logo-symbol);
}

.logo-k {
  color: var(--color-logo-k);
}

.logo-amoza {
  color: var(--color-logo-amoza);
}

.logo-kz {
  color: var(--color-logo-kz);
}

/* .logo-symbol, */
.logo-k,
.logo-amoza,
.logo-kz {
  text-shadow: 1px 1px 2px var(--color-logo-shadow);
}

/* === Анимации === */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-out;
}

/* === Пульсация === */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.hover-pulse:hover {
  animation: pulse 1s infinite;
}

.loader {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* === Адаптивные стили === */
@media (max-width: 640px) {
  h1 {
    font-size: theme('fontSize.xl');
  }
  h2 {
    font-size: theme('fontSize.lg');
  }
  .card {
    @apply p-4;
  }
  .modal-content {
    width: 90%;
  }
}

@media (max-width: 320px) {
  .text-lg {
    font-size: theme('fontSize.base');
  }
}
